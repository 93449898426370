import React from "react";

function Footer({lang}) {
  return (
    <div>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h3>{lang === "es" ? "Singular City" : "Singular City"}</h3>
                <p>
                  Contáctanos y descubre cómo podemos transformar tu
                  infraestructura de transporte para ofrecer una experiencia
                  excepcional a tus usuarios
                </p>
              </div>
            </div>
            <div className="social-links">
              <a
                href="https://www.linkedin.com/company/singularcity"
                className="linkedin"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="container footer-bottom clearfix">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>{lang === "es" ? "Singular City" : "Singular City"} SAS 2024</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">Desarrollado con 💛 </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
