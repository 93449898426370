import React from "react";
import IconBox from "./IconBox";
/* import PDFViewer from "./PDFViewer"; */
import barilo from "../assets/img/barilo.jpg";
import informeFormosa from "../assets/img/InformeFormosa.jpg";
import totem from "../assets/img/Totem.jpg";

function Home({lang}) {
  return (
    <div>
      <main id="main">
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="about-img">
                  <img
                    src="assets/img/15'.jpg"
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <br></br>
                <br></br>
                <h3>
                  En {lang === "es" ? "Singular City" : "Singular City"}, buscamos enriquecer la experiencia de
                  movilidad en las ciudades. Creemos que la clave para una
                  movilidad sostenible radica no solo en sistemas de transporte
                  eficientes, sino también en entornos urbanos que fomenten la
                  actividad, la interacción social y la innovación.
                </h3>
                <p className="fst-italic"></p>
                <ul>
                  <li>
                    <i className="bi bi-check-circle"></i>
                    <h5>
                      Ofrecemos soluciones integrales para mejorar los sistemas
                      de transporte público.
                    </h5>
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Impulsamos la evolución de la movilidad a través de un
                      diagnóstico centrado en el usuario.
                    </h5>
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Valoramos la inclusión en los sistemas de movilidad.
                    </h5>
                  </li>
                </ul>
                <h5>
                  Esto significa diseñar soluciones que sean accesibles para
                  todos, independientemente de su ubicación, necesidades de
                  conectividad o discapacidades. Buscamos proporcionar
                  soluciones de transporte que mejoren la conectividad y
                  accesibilidad de las personas.
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="pt-4 pt-lg-0 order-2 order-lg-1 content">
                <br></br>
                <br></br>
                <h3>
                  Entendemos que una Ciudad Inteligente se define por la
                  eficiencia y sostenibilidad de sus sistemas, no solo por la
                  tecnología implementada.
                  <br></br>
                  <br></br>
                  Comprometidos con el uso responsable de los recursos locales,
                  trabajamos para mejorar la calidad de vida en las ciudades a
                  través de soluciones de movilidad innovadoras, que promuevan
                  un desarrollo urbano más armónico y sostenible. Nuestro
                  enfoque nos ha permitido implementar soluciones innovadoras y
                  sostenibles que han generado un impacto positivo notable en
                  distintas ciudades de Argentina.
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="our-values">
          <div className="container">
            <div className="row">
              <h1 className="h1">
                ¿Cómo avanzar hacia Ciudades Conectadas y Sostenibles?
              </h1>
              <div className="col-md-4 d-flex align-items-stretch">
                <div
                  className="card"
                  style={{
                    backgroundImage: 'url("assets/img/compu.png")',
                  }}
                >
                  <div className="card-body">
                    <h1 className="card-title">1</h1>
                    <h3 className="card-text">
                      Plan Estratégico para la Acción
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-stretch mt-4 mt-md-0">
                <div
                  className="card"
                  style={{
                    backgroundImage: 'url("assets/img/celular.png")',
                  }}
                >
                  <div className="card-body">
                    <h1 className="card-title">2</h1>
                    <h3 className="card-text">
                      Transformación Digital e Integración Avanzada de
                      Telemetría
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-stretch mt-4">
                <div
                  className="card"
                  style={{
                    backgroundImage: 'url("assets/img/totem.png")',
                  }}
                >
                  <div className="card-body">
                    <h1 className="card-title">3</h1>
                    <h3 className="card-text">
                      Movilidad de Próxima Generación: Eficiencia, Calidad y
                      Crecimiento Urbano
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======= End Our Values Section ======= */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="about-img">
                  <img
                    src="assets/img/1.1.png"
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <br></br>
                <br></br>
                <h3>
                  Colaboración Estratégica: Fomento de sinergias entre los
                  distintos actores del ecosistema de movilidad urbana.
                </h3>
                <p className="fst-italic"></p>
                <ul>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Evaluación de Eficiencia: Diagnóstico completo de la
                      operatividad del sistema de transporte, identificando
                      áreas críticas para optimizar recursos.
                    </h5>
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Experiencia del Usuario: Análisis profundo sobre
                      previsibilidad, accesibilidad a la información y
                      condiciones de espera.
                    </h5>
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Sostenibilidad y Electromovilidad: Revisión de prácticas
                      sostenibles y asesoramiento para la adopción de
                      innovaciones tecnológicas.
                    </h5>
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Colaboración Estratégica: Fomento de sinergias entre los
                      distintos actores del ecosistema de movilidad urbana.
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 order-2 order-lg-1"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="about-img">
                  <img
                    src="assets/img/georeferenciacion.jpg"
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <br></br>
                <br></br>
                <h3>
                  Avanzamos hacia la digitalización completa del transporte
                  urbano, implementando soluciones de telemetría y planificación
                  de viajes para una gestión eficiente.
                </h3>
                <p className="fst-italic"></p>
                <ul>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Mapeo y Georreferenciación: Utilización de tecnología de
                      punta para el análisis detallado del sistema y entorno
                      urbano.
                    </h5>
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Integración Tecnológica Avanzada: Incorporación de
                      telemetría y herramientas digitales (GTFS, GBFS) para una
                      gestión optimizada de flotas y planificación de viajes.
                    </h5>
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Reestructuración de Paradas: Mejora de la accesibilidad y
                      eficiencia del transporte público mediante la
                      reorganización del sistema de paradas.
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="about-img">
                  <img
                    src="assets/img/infraestructura.jpg"
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <br></br>
                <br></br>
                <h3>
                  Soluciones de vanguardia para la optimización de sistemas de
                  transporte, desarrollo urbano sostenible y experiencias de
                  viaje superiores.
                </h3>
                <p className="fst-italic"></p>
                <ul>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Optimización del Sistema: Simulación de escenarios para el
                      diseño de trazas y paradas. Definición de frecuencias
                      óptimas y diseño de infraestructuras para maximizar la
                      eficiencia.
                    </h5>
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Redefinición del Espacio Urbano: Diseño de
                      infraestructuras urbanas integradas y sugerencias para el
                      ordenamiento territorial, mejorando el espacio público y
                      la conectividad.
                    </h5>
                  </li>
                  <li>
                    <i className="bi bi-check-circle"></i>{" "}
                    <h5>
                      Comunicación y Diseño de Identidad: Estrategias para
                      mejorar la percepción y experiencia del usuario.
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <br></br>
        <br></br>
        <div className="section-title">
          <h2>Transformación Urbana: Relatos de Innovación</h2>
        </div>
        <section id="casos" className="services">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <IconBox
                  backgroundImage={barilo}
                  title="Región Patagonia"
                  description="Se llevaron a cabo proyectos piloto y operativos para mejorar la circulación en las avenidas principales y áreas críticas de la ciudad. Se optimizó el servicio de estacionamiento medido, se planificaron y comunicaron cortes de calles, y se relevó la carga y descarga de mercancías. Además, se implementó la georreferenciación y digitalización de paradas de transporte público, facilitando la actualización y acceso a información en tiempo real para los usuarios."
                />
              </div>
              <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
                <IconBox
                  backgroundImage={informeFormosa}
                  title="Región Norte"
                  description="
                  Nuestra investigación abordó operaciones y experiencias de usuarios para diseñar soluciones efectivas al transporte urbano. Redefinimos trazas, ajustamos frecuencias y evaluamos su impacto. Recomendaciones mejoran eficiencia, experiencia y visión de movilidad sostenible. Se creó un centro de monitoreo del transporte público en la plaza principal de la ciudad, permitiendo un mejor seguimiento de la flota."
                />
              </div>

              <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                <IconBox
                  backgroundImage={totem}
                  title="Región Centro"
                  description="Tenemos en cuenta diferentes factores, como la actividad económica, los servicios públicos, la seguridad y la planificación de la ciudad en general. Una parada bien ubicada no solo facilita el transporte público, sino que también impulsa la dinámica de las ciudades. 
                  Realizamos un análisis detallado del entorno urbano para identificar las áreas de mayor necesidad y potencial. Se realizó la construcción de estaciones de Transbordo en la transformación del sistema de Transporte Público de la Provincia. Son estaciones
                  en zonas estratégicamente localizadas con orientación al desarrollo urbanístico, equipadas con andenes estructurados segun zona de cobertura de servicios "
                />
              </div>
            </div>
          </div>
        </section>{" "}
        {/* ======= End Service section ======= */}
        {/* ======= PDF section ======= */}
        {/* <div className="pdf">
          <PDFViewer pdfUrl="/pdf/dossierint.pdf" />
        </div> */}
        {/* ======= End PDF section ======= */}
        {/* ======= Clients Section ======= */}
        <div className="section-title">
          <h2>Confían en nosotros</h2>
        </div>
        <section id="clients" className="clients section-bg">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src="assets/img/formosa1.png"
                  className="img-fluid client-img"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src="assets/img/bari1.png"
                  className="img-fluid client-img"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src="assets/img/wara1.png"
                  className="img-fluid client-img"
                  alt=""
                />
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src="assets/img/ualabee1.png"
                  className="img-fluid client-img"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Clients Section --> */}
      </main>
      {/* <!-- ======= Contact Section ======= --> */}
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h2>Contacto</h2>
            <h4>
              Nuestras oficinas se encuentran en la ciudad de San Juan,
              Argentina{" "}
            </h4>
          </div>

          <div>
            <iframe
              style={{ border: "0", width: "100%", height: "270px" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1514.64580766024!2d-68.51828651107886!3d-31.54434731271875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96816a993ba372e7%3A0x1fd33fd346a21e59!2sAntonino%20Aberastain%20Sur%20854%2C%20J5402EQR%20San%20Juan!5e0!3m2!1ses-419!2sar!4v1685994317382!5m2!1ses-419!2sar"
              allowFullScreen
              title="Google Map"
            ></iframe>
          </div>

          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Ubicación:</h4>
                  <p>Aberastain 854 (S), San Juan - Argentina CP:5400</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>soporte@singularcity.com.ar</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Telefono:</h4>
                  <p>+5492644000555</p>
                </div>
              </div>
            </div>
            <div class="col-lg-8 mt-5 mt-lg-0">
              <div className="d-flex align-items-center justify-content-end">
                <img
                  src="assets/img/logoSC.jpg"
                  className="img-fluid"
                  alt=""
                  style={{ width: "30%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
