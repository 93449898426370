import React from "react";

function Header({ lang }) {

  return (
    <div>
      {/* ======= Header ======= */}
      <header id="header" className="d-flex align-items-center ">
        <div className="container-fluid d-flex align-items-center justify-content-lg-between">
          <a href="index.html" className="logo me-auto me-lg-0">
            <img
              src="assets/img/isologo_inferior.png"
              alt=""
              className="img-fluid"
            />
          </a>
          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Inicio
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  Nosotros
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#services">
                  Servicios
                </a>
              </li>
              {/* <li><a className="nav-link scrollto" href="#team">Equipo</a></li> */}
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contacto
                </a>
              </li>
            </ul>
          </nav>
          {/* .navbar */}
          <div className="header-social-links d-flex align-items-center">
            {/* <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
              <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                <a href="#" className="instagram"><i className="bi bi-instagram"></i></a> */}
              <a
              href="https://www.linkedin.com/company/singularcity"
              className="linkedin"
            >
              <i className="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </header>
      {/* End Header */}
     {/* ======= Hero Section ======= */}
     <section id="hero">
  <div className="hero-container">
    
    {<h1>{lang === "es" ? "Singular City" : "Singular City"}</h1>}
  </div>
</section>
      {/* ======= End Hero Section ======= */}
      

      
    </div>
  );
}

export default Header;
