import React, { useState } from 'react';

function IconBox({ backgroundImage, title, description }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="icon-box"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h4 className="title">{title}</h4>
      <p className={`description ${isHovered ? 'description-visible' : ''}`}>
        {description}
      </p>
    </div>
  );
}

export default IconBox;

