import Home from './Components/home';
import Header from './Components/header';
import Footer from './Components/footer';


function App() {
  return (
    <div>
      <Header lang="en" />
      <Home lang="en"/>
      <Footer lang="en"/>
    </div>
  );
}

export default App;
